export const dateCmp = (a: Date | string, b: Date | string): number =>
  new Date(a).valueOf() - new Date(b).valueOf()

export const stripTime = (date: Date): null | string => {
  if (!isValidDate(date)) return null

  // Extract the year, month, and date in the local timezone
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const isValidDate = (date: Date) => Number.isFinite(date.getTime())
export const asValidDate = (date: Date) => (isValidDate(date) ? date : undefined)
export const ifValidDate = (v: unknown): Date | undefined =>
  v && v instanceof Date && isValidDate(v) ? v : undefined

export function toDate(v: unknown, options?: { stripTime?: boolean }): undefined | Date {
  const defaultOptions = { stripTime: false }
  const { stripTime } = { ...defaultOptions, ...options }

  const parsedDate = _toDate(v)

  if (parsedDate && stripTime) {
    return new Date(parsedDate.toISOString().split('T', 1)[0])
  }

  return parsedDate
}

function _toDate(v: unknown): undefined | Date {
  switch (typeof v) {
    case 'undefined': {
      return undefined
    }
    case 'object': {
      return ifValidDate(v)
    }
    case 'number': {
      if (Number.isFinite(v)) return new Date(v * 1e3) // epoch
      return undefined
    }
    case 'string': {
      if (v === '') return undefined
      if (v === 'now') return new Date()

      const match = v.match(/^[+-]\d+$/)
      if (match) {
        const delta = Number(v) * 1e3
        // asValidDate() not needed here because we know the delta is a valid int
        return new Date(Date.now() + delta)
      }

      const d = new Date(v.includes('T') ? v : `${v} 12:00`)

      return asValidDate(d) ? d : asValidDate(new Date(v))
    }
    default: {
      return undefined
    }
  }
}

export const normalizeDate = (v?: Date | string | 'now' | null): null | string => {
  const date = v == null ? null : toDate(v)
  if (!date) return null

  return stripTime(date)
}

export const formatDate = (input: Date | string | 'now' | null, pattern: string): null | string => {
  const iso = normalizeDate(input)
  if (!iso) return null
  const [year, month, day] = iso.split('-')
  const localized = pattern
    .replace('d', day.replace(/^0+/, ''))
    .replace('D', day)
    .replace('m', month.replace(/^0+/, ''))
    .replace('M', month)
    // .replace('y', year.replace(/^0+/, '')) // Makes no sense
    .replace('Y', year)
  return localized
}

export const extractDateParts = (
  localized: any,
  pattern: string
): null | {
  Y: number
  M: number
  D: number
} => {
  if (typeof localized !== 'string') return null

  const parts = pattern.split(/[^A-Z]+/g)
  const values = localized
    .replace(/(^[^\w\d])|([^\w\d]$)/g, '')
    .replace(/[^\w\d]+/g, '-')
    .split('-')

  if (values.length !== parts.length) return null

  const {
    Y = NaN,
    M = NaN,
    D = 1,
  } = parts.reduce((g, p, i) => ({ ...g, [p]: Number(values[i]) }), {}) as {
    Y?: number
    M?: number
    D?: number
  }

  if (isNaN(Y) || !(M >= 1 && M <= 12) || !(D >= 1 && D <= 31)) return null

  return { Y, M, D }
}

export const parseDate = (localized: string, pattern: string): null | string => {
  if (typeof localized !== 'string') return null

  if (/^\d{4}-\d{2}-\d{2}$/.test(localized)) {
    return stripTime(new Date(localized))
  }

  const parts = extractDateParts(localized, pattern)
  if (!parts) return null

  const day = String(parts.D).padStart(2, '0')
  const month = String(parts.M).padStart(2, '0')
  const year = String(fixCentury(parts.Y)).padStart(4, '0')

  return stripTime(new Date(`${year}-${month}-${day}`))
}

export const fixCentury = (year: number): number => {
  if (year >= 100) return year
  if (year < 0) return year

  const currentYear = new Date().getUTCFullYear()
  const prefix = (currentYear / 100) | 0
  const suffix = currentYear % 100

  return year > suffix ? (prefix - 1) * 100 + year : prefix * 100 + year
}

export const addSeconds = (date: Date, seconds: number): Date => {
  const copy = new Date(date)
  return new Date(copy.getTime() + seconds * 1e3)
}

export const addMinutes = (date: Date, minutes: number): Date => {
  const copy = new Date(date)
  return new Date(copy.getTime() + minutes * 6e4)
}
